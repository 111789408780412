(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("zepto"), require("image-client-api"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "zepto", "image-client-api"], factory);
	else if(typeof exports === 'object')
		exports["warmupUtils"] = factory(require("lodash"), require("zepto"), require("image-client-api"));
	else
		root["warmupUtils"] = factory(root["lodash"], root["zepto"], root["image-client-api"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__31__) {
return 